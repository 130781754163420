import { utils } from 'xlsx';
import { FaFileDownload } from "react-icons/fa";
import { torres } from '../../../helpers/data';
import XLSX from "sheetjs-style";


  export const downloadExcelAudit = (
    year,
    setYearSelected,
    yearSelected,
    info,
    setYearGenerate,
    offices,
    filter
  ) => {
      const data = {};
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const startYear = 2023;

      const getMonthName = (monthNumber) => {
        const months = [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];
        return months[monthNumber];
      };
    
      const monthsToFetch = [];
     for (let y = startYear; y <= currentYear; y++) {
    const startMonth = y === startYear ? 0 : 0; // Siempre comienza desde enero
    const endMonth = y === currentYear ? currentMonth : 11; // Hasta el mes actual si es el año actual, de lo contrario diciembre
    for (let m = startMonth; m <= endMonth; m++) {
      monthsToFetch.push({ year: y, month: m });
    }
  }
    
      if (!info) {
        console.error("No se encontraron datos para generar el Excel.");
        return;
      }
    
      monthsToFetch.forEach(({ year, month }) => {
        const monthName = getMonthName(month);
        const monthDataKey = `${monthName} ${year}`;
        const monthData = info?.groupedByMonth[monthDataKey];
        
    
        data[monthDataKey] = monthData?.map((item) => ({
          office: item.office,
          consumoTotalMes: parseFloat(item.consumoTotalMes),
          mesProrrateado: item.mesProrrateado,
          unidad: item.unidad || "",
          tiposConsumo: item.tiposConsumo || [],
          days: item.diasCalculados,
        }));
    
    
        offices?.forEach((office) => {
          if (!data[monthDataKey]?.some((item) => item?.office === office?.name)) {
            data[monthDataKey]?.push({
              office: office.name,
              consumoTotalMes: 0.0,
              mesProrrateado: monthDataKey,
              unidad: "",
              tiposConsumo: [],
            });
          }
        });
      });
    
      const filteredData = {};
      Object.keys(data).forEach(month => {
        if (month.includes(year)) {
          filteredData[month] = data[month];
          filteredData[month] = filteredData[month]?.filter(item => 
            !(item?.office === "SUCURSAL 3012 TORRE BBVA" && item?.tiposConsumo?.includes("Energía Renovable"))
          );
          const torresE = data[month]?.filter(i => torres?.includes(i?.office) && i?.tiposConsumo?.includes("Energía Consumida"));
          const totalConsumo = torresE?.reduce((sum, item) => sum + item?.consumoTotalMes, 0);
          const total3Picos = data[month]?.find(i => i?.office === "SUCURSAL 3012 TORRE BBVA" && i?.tiposConsumo?.includes("Energía Renovable"))?.consumoTotalMes || 0;
          
          const newTorresE = torresE?.map(item => {
            const percentage = totalConsumo > 0 ? item?.consumoTotalMes / totalConsumo : 0;
            const consumo3PRenov = (percentage * total3Picos).toFixed(2);
            return {
              ...item,
              consumo3PRenov: parseFloat(consumo3PRenov)
            };
          });
          
          newTorresE?.forEach(newItem => {
            const matchingItem = filteredData[month]?.find(i => i?.office === newItem?.office && i?.tiposConsumo?.includes("Energía Renovable"));
            if(newItem?.consumo3PRenov > 0){
              if (matchingItem) {
                matchingItem.consumoTotalMes += newItem?.consumo3PRenov;
              } else {
                filteredData[month].push({
                  office: newItem?.office,
                  consumoTotalMes: newItem?.consumo3PRenov,
                  mesProrrateado: newItem?.mesProrrateado,
                  unidad: newItem?.unidad,
                  tiposConsumo: ["Energía Renovable"],
                  days: newItem?.days
                });
              }
            }
          });
        }
      });

      if (Object.keys(filteredData).length === 0) {
        console.error(`No data found for year ${year}`);
        return;
      }

      const wb = XLSX.utils.book_new();
      const consumoTypes = ['Energía Consumida', 'Agua Consumida', 'Volumen Registrado', "Energía Renovable", "Energía Autogenerada"];

      consumoTypes.forEach(type => {
        const ws = utils.aoa_to_sheet([]);
        const meses = Object.keys(filteredData);

        const style = {
          font: {
            name: 'Arial',
            sz: 10,
            bold: true
          }
        };

        const consumptionDescription = type === 'Energía Consumida' || type === "Energía Renovable" || type === "Energía Autogenerada" ? 'Consumos en kWh' :
          type === 'Agua Consumida' ? 'Consumo en m3 (Agua)' : 'Consumo en m3 (Gas)';
        const daysCellDescription = "Días de consumo contabilizados";

        const styledCell = { v: consumptionDescription, s: style };
        const daysStyledCell = { v: daysCellDescription, s: style };
        const header = ['Nombre del sitio', ...meses];
        if (type === 'Agua Consumida') {
          header.push('', 'MEDIDO', '', ...meses);
          utils.sheet_add_aoa(ws, [[styledCell]], { origin: { r: 0, c: 1 } });
          utils.sheet_add_aoa(ws, [[daysStyledCell]], { origin: { r: 0, c: meses.length + 4 } });
        } else {
          header.push('', ...meses);
          utils.sheet_add_aoa(ws, [[styledCell]], { origin: { r: 0, c: 1 } });
          utils.sheet_add_aoa(ws, [[daysStyledCell]], { origin: { r: 0, c: meses.length + 1 } });
        }
        utils.sheet_add_aoa(ws, [header], { origin: { r: 1, c: 0 } });

        const wscols = [{ wch: 25 }];
        meses.forEach(() => { wscols.push({ wch: 10 }); });
        if (type === 'Agua Consumida') {
          wscols.push({ wch: 10 }); // Espacio antes de "MEDIDO"
          wscols.push({ wch: 10 }); // Columna "MEDIDO"
          wscols.push({ wch: 10 }); // Espacio después de "MEDIDO"
        }
        meses.forEach(() => { wscols.push({ wch: 10 }); });
        ws['!cols'] = wscols;

        ws['!merges'] = [
          { s: { r: 0, c: 1 }, e: { r: 0, c: meses.length } },
          { s: { r: 0, c: type === 'Agua Consumida' ? meses.length + 4 : meses.length + 1 }, e: { r: 0, c: type === 'Agua Consumida' ? meses.length + 6 : meses.length + 3 } }
        ];

        let rowIdx = 2;
        const uniqueSites = new Set();
        const targetSites =
        [
          "SUCURSAL 061 LAS HERAS",
          "SUCURSAL 066 FISHERTON",
          "SUCURSAL 072 SAN JUAN",
          "SUCURSAL 074 PARANA",
          "SUCURSAL 077 TANDIL",
          "SUCURSAL 081 ROSARIO",
          "SUCURSAL 085 MENDOZA",
          "SUCURSAL 089 NEUQUEN",
          "SUCURSAL 091 PERGAMINO",
          "SUCURSAL 097 C. RIVADAVIA",
          "SUCURSAL 128 LA PLATA NORTE",
          "SUCURSAL 180 ENSENADA",
          "SUCURSAL 183 CITY BELL",
          "SUCURSAL 191 GRAL. RODRIGUEZ",
          "SUCURSAL 201 ARROYITO",
          "SUCURSAL 202 CORDOBA CENTRO",
          "SUCURSAL 206 CALETA OLIVIA",
          "SUCURSAL 212 CONCEP. DEL URUGU",
          "SUCURSAL 217 NEUQUEN",
          "SUCURSAL 228 RIO GRANDE",
          "SUCURSAL 243 POSADAS",
          "SUCURSAL 244 AV. COLON",
          "SUCURSAL 252 ESQUEL",
          "SUCURSAL 258 BARILOCHE",
          "SUCURSAL 259 ALTA CORDOBA",
          "SUCURSAL 272 RIO GALLEGOS",
          "SUCURSAL 282 PASEO DEL SIGLO",
          "SUCURSAL 285 GODOY CRUZ (MZA)",
          "SUCURSAL 291 NUEVA CORDOBA",
          "SUCURSAL 297 TRELEW",
          "SUCURSAL 299 USHUAIA",
          "SUCURSAL 361 NUEVA LA PLATA",
          "SUCURSAL 381 RIVADAVIA SJ",
          "SUCURSAL 387 NUEVA COMOD RIVAD",
          "SUCURSAL 386 ARROYITO CORDOBA"
        ]

        meses?.forEach(mes => {
          filteredData[mes]?.forEach(entry => {
            if (entry?.tiposConsumo?.includes(type)) {
              uniqueSites?.add(entry?.office?.trim());
            }
          });
        });

        const uniqueSitesArray = Array.from(uniqueSites).sort();
        
        const filteredSitesArray = filter && filter.length > 0 ? uniqueSitesArray.filter(site => filter.includes(site)) : uniqueSitesArray;

        
        const months = [
          'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
          'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
        ];
        
        const getDaysInMonth = (monthName, year) => {
          
          // Convertir el nombre del mes a índice (0 para enero, 1 para febrero, etc.)
          const monthIndex = months.indexOf(monthName.toLowerCase());
        
          if (monthIndex === -1) {
            throw new Error(`Invalid month name: ${monthName}`);
          }
        
          // Obtener el número de días del mes
          return new Date(year, monthIndex + 1, 0).getDate();
        };
        
        // Función para aplicar estilo a una celda
          const applyStyle = (value, condition, style) => {
            return condition ? { v: value, s: style } : { v: value }; // Deja la celda en blanco si no hay datos
          };

          filteredSitesArray.forEach(site => {
            const row = [site.trim()];
            const daysRow = [];
            
            meses.forEach((mes) => {
              const [monthName, year] = mes.split(' ');
              const yearNumber = parseInt(year, 10);         
              const diasDelMes = getDaysInMonth(monthName, yearNumber);
              const entry = filteredData[mes]?.find(i => i?.office?.trim() === site && i?.tiposConsumo?.includes(type));

              const consumptionStyle = {
                font: { name: 'Calibri', sz: 11 },
                fill: { fgColor: { rgb: "FFFF0000" } }
              };
              const daysStyle = {
                font: { name: 'Calibri', sz: 11 },
                fill: { fgColor: { rgb: "FFFF0000" } }
              };
              const currentYear = new Date().getFullYear();
              const currentMonth = new Date().getMonth(); 
              const isCurrentMonth = (months.indexOf(mes.split(' ')[0].toLowerCase()) === currentMonth && mes.includes(currentYear));
              const isPreviousMonth = (months.indexOf(mes.split(' ')[0].toLowerCase()) === (currentMonth - 1) && mes.includes(currentYear));
              
              if (entry) {
                // Aplicar estilo solo si hay datos
                const isProrrateado = (entry?.days < diasDelMes) && (!isCurrentMonth && !isPreviousMonth); // Verificamos si los días son menores
                const cellValue = applyStyle(entry.consumoTotalMes, isProrrateado, consumptionStyle);
                row.push(cellValue);

                // Aplicar estilo a los días si son menores que los días del mes
                const daysValue = applyStyle(entry.days, isProrrateado, daysStyle);
                daysRow.push(daysValue);
              } else {
                // Añadir "N/A" sin estilo
                row.push({ v: "N/A" });
                daysRow.push({ v: "N/A" });
              }
            });

            // Añadir las filas a la hoja
            utils.sheet_add_aoa(ws, [row], { origin: { r: rowIdx, c: 0 } });

            if (type === 'Agua Consumida') {
              row.push(""); // Espacio antes de "MEDIDO"
              row.push(targetSites.includes(site.trim()) ? "NO" : "SI"); // "MEDIDO"
              row.push(""); // Espacio después de "MEDIDO"
            } else  {
              row.push(""); // Columna vacía
            } 
             
            utils.sheet_add_aoa(ws, [row.concat(daysRow)], { origin: { r: rowIdx++, c: 0 } });
          });
          if (!filter || filter.length === 0) {
            utils.sheet_add_aoa(ws, [['']], { origin: { r: rowIdx++, c: 0 } });
  
            const totalsRow = ['TOTAL ' + (type === 'Energía Consumida' || type ==="Energía Renovable" || type === "Energía Autogenerada" ? 'kWh' : 'm3') + ' mensual'];
            const percentagesRow = ['% procesado mensual'];
            meses.forEach(mes => {
              // Extraer el nombre del mes y el año de la cadena 'mes'
              const [mesNombre, year] = mes.split(' ');
              // Encontrar el índice del mes en el array 'meses'
              const mesIndex = meses.findIndex(m => m.startsWith(mesNombre));
            
              // Calcular el total mensual y los días procesados
              const monthlyTotal = filteredData[mes]
                ?.filter(e => e.tiposConsumo.includes(type))
                ?.reduce((acc, curr) => acc + (curr.consumoTotalMes || 0), 0);
              const days = filteredData[mes]
                ?.filter(e => e.tiposConsumo.includes(type))
                ?.reduce((acc, curr) => acc + (curr.days || 0), 0);
            
              const totalSitesPerMonth = uniqueSites.size; // Número de sitios activos por mes
            
              // Crear la fecha para el último día del mes correspondiente al año extraído
              const totalExpected = totalSitesPerMonth * new Date(year, mesIndex + 1, 0).getDate();
              const percentage = (days / totalExpected) * 100;
            
              totalsRow.push(monthlyTotal?.toFixed(2));
              percentagesRow.push(percentage?.toFixed(2) + '%');
            });
            
  
            utils.sheet_add_aoa(ws, [totalsRow], { origin: { r: rowIdx++, c: 0 } });
            utils.sheet_add_aoa(ws, [percentagesRow], { origin: { r: rowIdx++, c: 0 } });
          }
          const typeName = type === 'Energía Consumida' ? 'Electricidad' :
          type === 'Volumen Registrado' ? 'Gas' :
          type === 'Energía Renovable' ? 'Energía Renovable' : 
          type === 'Energía Autogenerada' ? 'Energía Autogenerada' : 'Agua';

        XLSX.utils.book_append_sheet(wb, ws, typeName);
      });

      XLSX.writeFile(wb, `Reporte_Sostenibilidad_${year}.xlsx`);
    
  };
