import {
  dataStart,
  dataSuccess,
  dataFailure,
  generalDataStart,
  generalDataSuccess,
  generalDataFailure,
  billStart,
  billSuccess,
  billFailure,
  uploadedBillsFailure,
  uploadedBillsStart,
  uploadedBillsSuccess,
  deleteBillsStart,
  deleteBillsSuccess,
  deleteBillsFailure,
  anualDataStart,
  anualDataSuccess,
  anualDataFailure,
  generalDataSuccessCO2,
  generalDataStartCO2,
  generalDataFailureCO2,
  prorrateo2023Start,
  prorrateo2023Success,
  prorrateo2023Failure,
  prorrateo2024Start,
  prorrateo2024Failure,
  prorrateo2024Success,
  prorrateo2025Start,
  prorrateo2025Failure,
  prorrateo2025Success,
} from "../reducers/dataReducer";
import { publicRequest } from "../../requestMeth";
import {
  editBillFailure,
  editBillStart,
  editBillSuccess,
  existBillFailure,
  existBillStart,
  existBillSuccess,
  previousDateStart, 
  previousDateSuccess, 
  previousDateFailure
} from "../reducers/editBillReducer";

export const getData = async (dispatch, token, filterOptions) => {
  dispatch(dataStart());
  try {
    let res;
    if (filterOptions.startDate && !filterOptions.endDate) {
      res = await publicRequest.get(
        `/data?startDate=${filterOptions.startDate}&type=${filterOptions.class}&state=${filterOptions.provinces}&company=${filterOptions.companies}&utilityType=${filterOptions.utilities}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );
    } else if (filterOptions.startDate && filterOptions.endDate) {
      res = await publicRequest.get(
        `/data?startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}&type=${filterOptions.class}&state=${filterOptions.provinces}&company=${filterOptions.companies}&utilityType=${filterOptions.utilities}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );
    } else {
      res = await publicRequest.get(
        `/data?type=${filterOptions.class}&state=${filterOptions.provinces}&company=${filterOptions.companies}&utilityType=${filterOptions.utilities}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );
    }
    dispatch(dataSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(dataFailure());
  }
};

export const getGeneralData = async (dispatch, token) => {
  dispatch(generalDataStart());
  try {
    const res = await publicRequest.get(`/data/general`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(generalDataSuccess(res.data));
  } catch (error) {
    dispatch(generalDataFailure({}));
    return error?.response?.data;
  }
};

export const getAnualData = async (dispatch, token) => {
  dispatch(anualDataStart());
  try {
    const res = await publicRequest.get(`/data/anual`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(anualDataSuccess(res.data));
  } catch (error) {
    dispatch(anualDataFailure({}));
    return error?.response?.data;
  }
};

export const getConsumptionData = async (dispatch, token, year) => {
  // Mapa para asociar años con acciones específicas
  const actions = {
    '2023': {
      start: prorrateo2023Start,
      success: prorrateo2023Success,
      failure: prorrateo2023Failure,
    },
    '2024': {
      start: prorrateo2024Start,
      success: prorrateo2024Success,
      failure: prorrateo2024Failure,
    },
    '2025': {
      start: prorrateo2025Start,
      success: prorrateo2025Success,
      failure: prorrateo2025Failure,
    },
  };

  const yearActions = actions[year];

  if (!yearActions) {
    throw new Error(`No actions defined for year ${year}`);
  }

  dispatch(yearActions.start());
  
  try {
    const res = await publicRequest.get(`/data/consumption?year=${year}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(yearActions.success(res.data));
    return res.data;
  } catch (error) {
    dispatch(yearActions.failure({}));
    return error?.response?.data;
  }
};


export const createBill = async (dispatch, payload, token) => {
  dispatch(billStart());
  try {
    await publicRequest.post(`/data`, payload, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(billSuccess());
  } catch (error) {
    dispatch(billFailure(error.response.data));
    throw error.response.data;
  }
};

export const getUploadedBills = async (dispatch, token, filterOptions) => {
  dispatch(uploadedBillsStart());
  try {
    let url = "/data/bills";

    if (filterOptions.startDate) {
      if (filterOptions.endDate) {
        // Caso: startDate y endDate están presentes
        url += `?startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}`;
      } else {
        // Caso: solo startDate está presente
        url += `?startDate=${filterOptions.startDate}`;
      }
    }

    let res = await publicRequest.get(url, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(uploadedBillsSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(uploadedBillsFailure([]));
  }
};

export const editBill = async (dispatch, id, payload, token) => {
  dispatch(editBillStart());
  try {
    const res = await publicRequest.put(`/data/bill/${id}`, payload, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(editBillSuccess(res.data));
  } catch (error) {
    dispatch(editBillFailure());
  }
};

export const deleteBill = async (dispatch, id, token) => {
  dispatch(deleteBillsStart());
  try {
    const res = await publicRequest.delete(`/data/bill/${id}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(deleteBillsSuccess(res.data));
  } catch (error) {
    dispatch(deleteBillsFailure([]));
    return error?.response?.data;
  }
};

export const existBill = async (dispatch, id, type, token) => {
  dispatch(existBillStart());
  try {
    const res = await publicRequest.get(`/data/bill/${id}?type=${type}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(existBillSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(existBillFailure());
  }
};

export const getGeneralDataCO2 = async (dispatch, token) => {
  dispatch(generalDataStartCO2());
  try {
    const res = await publicRequest.get(`/data/generalCO2`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(generalDataSuccessCO2(res.data));
  } catch (error) {
    dispatch(generalDataFailureCO2({}));
    return error?.response?.data;
  }
};

export const getPreviousDate = async (dispatch, payload, token) => {
  dispatch(previousDateStart());
  try {
    const res = await publicRequest.get(`/data/lastBill?account=${payload}`, {
      headers: { token: `Bearer ${token}` },
    });
    dispatch(previousDateSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(previousDateFailure({}));
    return error?.response?.data;
  }
};
