import "./FinanceDashboard.css";
import React from "react";
import DonutChart from "../../Charts/DonutChart";
import BarChart from "../../Charts/BarChart";
import TableComponent from "../TableComponent/TableComponent";
import { provinces } from "../../../helpers/infoFilter";
import { FaBars } from "react-icons/fa";
import BillsExcel from "../../Reports/ReportsBillsExcel";
import { useSelector } from "react-redux";
import TableVendors from "../TableComponent/TableVendors";
import BulletChart from "../../Charts/BulletChart";
import ReportEconomicoPdf from "../../Reports/ReportEconomico";
import ProrrateoBoton from "../ProrrateoBoton";
import CompletitudBoton from "../CompletitudBoton";

const FinanceDashboard = ({ data, filterOptions, filterClass }) => {
  let selectDates =  localStorage?.getItem("SelectDates");
  selectDates = JSON.parse(selectDates)
  const dataFetching = useSelector((state) => state.data.isFetching);
  const filteredData = [];
  const utilityTypes = new Set();
  let filteredTotal;

  data?.forEach((item) => {
    if (!utilityTypes.has(item.utilityType)) {
      filteredData.push(item);
      utilityTypes.add(item.utilityType);
    }
  });

  if (filterClass.class.length === 0) {
    filteredTotal = data?.filter(
      (item) =>
        item.cost !== null &&
        !isNaN(parseFloat(item.cost)) &&
        isFinite(item.cost) &&
        item.cost !== " " &&
        item.lineItemText === "Total Factura"
    );
  } else {
    filteredTotal = data?.filter(
      (item) =>
        item.cost !== null &&
        !isNaN(parseFloat(item.cost)) &&
        isFinite(item.cost) &&
        item.cost !== " "
    );
  }

  const totalCost = filteredTotal?.reduce((acc, item) => {
    const costValue = parseFloat(item.cost);
    if (costValue !== -1) {
      acc += costValue 
    }
    return acc;
  }, 0);

  const formattedTotalCost = totalCost.toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });


  const utilityTypes1 = ["GAS NATURAL", "AGUA", "ELECTRICIDAD"];
  utilityTypes1.reduce((acc, utilityType) => {
    const filteredData = data?.filter(
      (item) =>
        item?.utilityType && item?.utilityType.trim() === utilityType.trim()
    );
    const totalCost = filteredData?.reduce((total, item) => {
      const cost = parseFloat(item?.cost);
      if (!isNaN(cost)) {
        return total + (cost > 0 ? cost : 0);
      }
      return total;
    }, 0);

    acc[utilityType] = totalCost?.toFixed(2);
    return acc;
  }, {});


  // const uniqueSuppliers = data?.reduce((unique, item) => {
  //   const firstWord = item.supplier?.trim().split(" ")[0].toLowerCase();

  //   return unique.includes(firstWord) ? unique : [...unique, firstWord];
  // }, []);

  // const totalUniqueSuppliers = uniqueSuppliers?.length;

  const nameProvinces = filterOptions?.provinces
    .map((value) => {
      const province = provinces.find((p) => p.value === value);
      return province ? province.name : value;
    })
    .join(", ");
    
    const formatInvoiceDataForExcel = (invoices) => {
      return invoices?.map((invoice) => {
        const formattedInvoice = {
          "nombre del sitio": invoice?.locationName,
          utilidad: invoice?.utilityType,
          proveedor: invoice?.supplier,
          nroCuenta: invoice?.AccountNumber,
          factura: invoice?.Bill_ID,
          lecturaActual: invoice?.readingDate,
          lecturaAnterior: invoice?.previousDate,
          emision: invoice?.invoiceDate,
          vencimiento: invoice?.dueDate,
          costo: invoice?.cost,
          consumo: invoice?.consumption,
          lineItemText: invoice?.lineItemText,
          class: invoice?.class,
          m2: invoice?.m2,
          units: invoice?.units
   
        };
 
        return formattedInvoice;
      });
    };

  // Obtener el mes y año actual
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();


    const stateCosts = {};
    const locationCosts = {};
  
    filteredTotal?.forEach((item) => {
      let state = item.state?.trim() || ''; 
      const cost = parseFloat(item.cost);
      if (!isNaN(cost)) {
        if (!stateCosts[state]) {
          stateCosts[state] = 0;
        }
        stateCosts[state] += cost;
      }
    });
    
    filteredTotal?.forEach((obj) => {
      const { locationName, cost } = obj;
  
      const costNumber = parseFloat(cost);
  
      if (!isNaN(costNumber) && cost !== "") {
        if (locationCosts.hasOwnProperty(locationName)) {
          locationCosts[locationName] += costNumber;
        } else {
          locationCosts[locationName] = costNumber;
        }
      }
    });


    const areDatesOnly =
    selectDates?.startDate &&
    selectDates?.endDate &&
    filterOptions?.date?.length === 0 &&
    filterOptions?.provinces?.length === 0 &&
    filterOptions?.companies?.length === 0 &&
    filterOptions?.utilities?.length === 0 &&
    filterOptions?.class?.length === 0;
  
  // Obtener el startDate y restar un día
  const startDate = new Date(selectDates?.startDate);
  startDate.setDate(startDate.getDate() - 1);
  
  // Obtener el mes de startDate (se suma 1 porque getMonth devuelve valores de 0 a 11)
  const startMonth = startDate.getMonth() + 1;
  
  // Obtener el mes actual y sumarle 1 día
  currentDate.setDate(currentDate.getDate() + 1);
  
  
  // Condición para mostrar el botón de descarga
  const showDownloadButton = startMonth !== currentMonth || !areDatesOnly;

// Agrupar y sumar costos por clase
const totalServices = data.reduce((acc, item) => {
  // Normalize class names
  let normalizedClass = item?.class?.trim();

  if (normalizedClass === "Impuesto" || normalizedClass === "Impuestos") {
    normalizedClass = "Impuestos";
  } else if (normalizedClass === "Otro" || normalizedClass === "Otros") {
    normalizedClass = "Otros";
  }

  if (normalizedClass !== "" && !normalizedClass?.includes("Total factura") && parseFloat(item?.cost) !== 0 && Number(item?.cost) !== 0
   && item?.class !== undefined && item?.class !== null && item?.class !== "N/A") {

    const existingItem = acc?.find((accItem) => accItem?.class === normalizedClass);
    if (existingItem) {
      existingItem.costTotal += parseFloat(item?.cost) || 0;
    } else {
      acc.push({
        class: normalizedClass,
        costTotal: parseFloat(item?.cost) || 0,
        units: item?.units || "",
      });
    }
  }
  return acc;
}, []);


  // Ordenar servicios por costo descendente
  totalServices.sort((a, b) => b.costTotal - a.costTotal);


  return (
    <>
    {dataFetching && (
      <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: "9999", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ textAlign: "center" }}>
          <div className="spinner-border" role="status" style={{ width: "4rem", height: "4rem" }}>
            <span className="visually-hidden">Cargando...</span>
          </div>
          <p style={{ marginTop: "10px" }}>Cargando...</p>
        </div>
      </div>
    )}
    <div className="template-bar" style={{ opacity: dataFetching ? "0.5" : "1" }}>
      <div className="template-title">
        <p className="title">REPORTE ECONÓMICO</p>
        <ul className="cont-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              REPORTES
              <FaBars />
            </a>
            <ul className="dropdown-menu" aria-label="Board">
            
            { showDownloadButton && (
                  <button>
                    {data ? (
                      <BillsExcel data={formatInvoiceDataForExcel(data)} date={filterOptions}/>
                    ) : null}
                  </button>
              )}
              
              <li>
              <ProrrateoBoton filter={filterOptions?.companies !== undefined ? filterOptions?.companies : []}/>
              </li> 
              <li style={{ width: "95%" }}>
              <CompletitudBoton  />
              </li>
              <li>
                <button>
                  <ReportEconomicoPdf filterOptions ={filterOptions}/>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="template-filters">
        <p>
        {
          selectDates?.startDate || selectDates?.endDate ? 
            `Fecha: ${
              selectDates?.startDate ? 
                new Date(new Date(selectDates?.startDate).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            } - ${
              selectDates?.endDate ? 
                new Date(new Date(selectDates?.endDate).getTime() - (24 * 60 * 60 * 1000)).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' }).replace(/\b\w{3}\b/g, match => match.charAt(0).toUpperCase() + match.slice(1)) : " "
            }` 
          : ""
        }
        </p>
        <p>
          {filterOptions.provinces.length > 0
            ? `Provincias: ${nameProvinces}`
            : null}
        </p>
        <p>
          {filterOptions.companies.length > 0
            ? `Sucursales: ${filterOptions.companies}`
            : null}
        </p>
        <p>
          {filterOptions.utilities.length > 0
            ? `Servicio: ${filterOptions.utilities}`
            : null}
        </p>
        <p>
          {filterClass.class.length > 0
            ? `Conceptos: ${filterClass.class}`
            : null}
        </p>
      </div>
    </div>
      <div className="parent-financeDash" id="div1-financeDash">
        <div className="div1-financeDash panel firstWidget">
          <h4 className="text-value">$ {formattedTotalCost}</h4>
          <label className="text-title">Total de gastos</label>
        </div>
        {/* Mostrar costos por clase */}
        {totalServices?.map((service, index) => (
          // Validar que costTotal no sea cero
          service.costTotal !== 0 && (
            <div key={index} className={`divE${index}-financeDash panel firstWidget`}>
              <h4 className="text-value">{`${service.costTotal.toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}</h4>
              <label className="text-title">{service.class}</label>
            </div>
          )
        ))}

        <div className="div5-financeDash panel">
        <BarChart data={data} type={"finance"}/>
        </div>
        <div className="div6-financeDash panel">
          <DonutChart type={"finance"} data={data} />
        </div>
        <div className="div7-financeDash panel">
          <DonutChart data={stateCosts} type={"summary"} />
        </div>
        <div className="div8-financeDash panel">
          <b className="titles">Conceptos</b>
          <TableComponent type={"utilityspend"} info={"financeItem"} data={data}/>
        </div>
        <div className="div9-financeDash panel table-responsive">
          <b className="titles">Proveedores</b>
          <TableVendors type={"vendor"} invoice={data} />
        </div>
        <div className="div10-financeDash panel">
          <b className="titles">Servicios</b>
          <TableComponent type={"commodity"} data={data} />
        </div>
        <div className="div11-financeDash panel">
          <TableComponent stateCosts={stateCosts} type={"provinces"} />
        </div>
        <div className="div12-financeDash panel" id="div6-corpSum">
          <BulletChart type={"costsOffice"} data={locationCosts} />
        </div>
        </div>
    </>
  );
};

export default FinanceDashboard;
