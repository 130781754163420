import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    currentData: null,
    generalData: null,
    generalDataCO2: null,
    anualData: null,
    prorrateo2023: null,
    prorrateo2024: null,
    prorrateo2025: null,
    uploadedBills: null,
    isFetching: false,
    isFetchingAnual: false,
    isFetchingGeneralData: false,
    isFetchingGeneralCo2: false,
    isFetchingProrrateo2023: false,
    isFetchingProrrateo2024: false,
    isFetchingProrrateo2025: false,
    billFetching: false,
    error: false,
    errorMessage: null
  },
  reducers: {
    dataStart: (state) => {
      state.isFetching = true;
    },
    dataSuccess: (state, action) => {
      state.isFetching = false;
      state.currentData = action.payload;
    },
    dataFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    generalDataStart: (state) => {
      state.isFetchingGeneralData = true;
    },
    generalDataSuccess: (state, action) => {
      state.isFetchingGeneralData = false;
      state.generalData = action.payload;
    },
    generalDataFailure: (state, action) => {
      state.isFetchingGeneralData = false;
      state.generalData = action.payload;
      state.error = true;
    },
    generalDataStartCO2: (state) => {
      state.isFetchingGeneralCo2 = true;
    },
    generalDataSuccessCO2: (state, action) => {
      state.isFetchingGeneralCo2 = false;
      state.generalDataCO2 = action.payload;
    },
    generalDataFailureCO2: (state, action) => {
      state.isFetchingGeneralCo2 = false;
      state.generalDataCO2 = action.payload;
      state.error = true;
    },
    anualDataStart: (state) => {
      state.isFetchingAnual = true;
    },
    anualDataSuccess: (state, action) => {
      state.isFetchingAnual = false;
      state.anualData = action.payload;
    },
    anualDataFailure: (state, action) => {
      state.isFetchingAnual = false;
      state.anualData = action.payload;
      state.error = true;
    },
    prorrateo2023Start: (state) => {
      state.isFetchingProrrateo2023 = true;
    },
    prorrateo2023Success: (state, action) => {
      state.isFetchingProrrateo2023 = false;
      state.prorrateo2023 = action.payload;
    },
    prorrateo2023Failure: (state, action) => {
      state.isFetchingProrrateo2023 = false;
      state.prorrateo2023 = action.payload;
      state.error = true;
    },
    prorrateo2024Start: (state) => {
      state.isFetchingProrrateo2024 = true;
    },
    prorrateo2024Success: (state, action) => {
      state.isFetchingProrrateo2024 = false;
      state.prorrateo2024 = action.payload;
    },
    prorrateo2024Failure: (state, action) => {
      state.isFetchingProrrateo2024 = false;
      state.prorrateo2024 = action.payload;
      state.error = true;
    },
    prorrateo2025Start: (state) => {
      state.isFetchingProrrateo2025 = true;
    },
    prorrateo2025Success: (state, action) => {
      state.isFetchingProrrateo2025 = false;
      state.prorrateo2025 = action.payload;
    },
    prorrateo2025Failure: (state, action) => {
      state.isFetchingProrrateo2025 = false;
      state.prorrateo2025 = action.payload;
      state.error = true;
    },


    logoutInfoData: (state) => {
      state.currentData = null;
      state.generalData = null;
      state.generalDataCO2 = null;
      state.anualData = null;
      state.prorrateo2023 = null;
      state.prorrateo2024 = null;
      state.uploadedBills = null;
      state.isFetching = false;
      state.isFetchingGeneralData = false;
      state.isFetchingProrrateo2023 = false;
      state.isFetchingProrrateo2024 = false;
      state.isFetchingGeneralCo2 = false;
      state.billFetching = false;
      state.errorMessage = null;
      state.error = false;
    },
    billStart: (state) => {
      state.billFetching = true;
      state.error = false;
    },
    billSuccess: (state, action) => {
      state.billFetching = false;
      state.error = false;
    },
    billFailure: (state, action) => {
      state.billFetching = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    uploadedBillsStart: (state) => {
      state.isFetching = true;
    },
    uploadedBillsSuccess: (state, action) => {
      state.isFetching = false;
      state.uploadedBills = action.payload;
    },
    uploadedBillsFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.uploadedBills = action.payload;
    },
    deleteBillsStart: (state) => {
      state.isFetching = true;
    },
    deleteBillsSuccess: (state, action) => {
      state.isFetching = false;
      state.uploadedBills = action.payload;
    },
    deleteBillsFailure: (state,action) => {
      state.isFetching = false;
      state.error = true;
      state.uploadedBills = action.payload;
    },
  },
});

export const { dataStart, dataSuccess, dataFailure, generalDataStart, generalDataSuccess, generalDataFailure, logoutInfoData, 
  billStart, billSuccess, billFailure, uploadedBillsFailure, uploadedBillsStart, 
  uploadedBillsSuccess, deleteBillsFailure, deleteBillsStart, deleteBillsSuccess,
 prorrateo2023Start,prorrateo2023Failure,prorrateo2023Success, prorrateo2024Start,prorrateo2024Failure,prorrateo2024Success, prorrateo2025Start,prorrateo2025Failure,prorrateo2025Success,
 anualDataFailure, anualDataStart, anualDataSuccess, generalDataFailureCO2, generalDataStartCO2,
generalDataSuccessCO2} = dataSlice.actions;
export default dataSlice.reducer;