// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audit-filters{
    display: flex;
    
}

.audit-select{
    width: 20%;
    margin-right: 5%;
}

.audit-contdownload{
    margin-top: 5%;
}

.audit-contdownload button{
    font-size: 15px;
    padding: 5px;
    border-radius: 0;
    background-color: #c3c6c9;
    color: #73879c;
    border: 1px solid #62778c;
    margin-right: 1%;
}

.audit-contdownload button i {
    margin-right: 5px;
    color: #62778c;
}

.audit-contdownload2 button{
    font-size: 15px;
    padding: 5px;
    border-radius: 0;
    background-color: #c3c6c9;
    color: #73879c;
    border: 1px solid #62778c;
    margin-right: 1%;
}

.audit-contdownload2 button i {
    margin-right: 5px;
    color: #62778c;
}

.audit-contdownload2 {
display: flex;
justify-content: center;
width: -moz-fit-content;
width: fit-content;
margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Audit/Audit.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;AACA,aAAa;AACb,uBAAuB;AACvB,uBAAkB;AAAlB,kBAAkB;AAClB,cAAc;AACd","sourcesContent":[".audit-filters{\n    display: flex;\n    \n}\n\n.audit-select{\n    width: 20%;\n    margin-right: 5%;\n}\n\n.audit-contdownload{\n    margin-top: 5%;\n}\n\n.audit-contdownload button{\n    font-size: 15px;\n    padding: 5px;\n    border-radius: 0;\n    background-color: #c3c6c9;\n    color: #73879c;\n    border: 1px solid #62778c;\n    margin-right: 1%;\n}\n\n.audit-contdownload button i {\n    margin-right: 5px;\n    color: #62778c;\n}\n\n.audit-contdownload2 button{\n    font-size: 15px;\n    padding: 5px;\n    border-radius: 0;\n    background-color: #c3c6c9;\n    color: #73879c;\n    border: 1px solid #62778c;\n    margin-right: 1%;\n}\n\n.audit-contdownload2 button i {\n    margin-right: 5px;\n    color: #62778c;\n}\n\n.audit-contdownload2 {\ndisplay: flex;\njustify-content: center;\nwidth: fit-content;\nmargin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
